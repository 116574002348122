import { setRegisteredData } from "./forms/registered-data";


// Przechowujemy poprzednio zaznaczony radio button
let previousRadio = null;

$('input[type="radio"]').on('click', function() {
    let disabled = true;
    if (this === previousRadio) {
        $(this).prop('checked', false);
        $('[name="personal_data[billType]"]').first().prop('checked', true);
        previousRadio = null;
        disabled = false;
    } else {
        previousRadio = this;
    }
    $('[name="personal_data[billPos]"]', this.form.elements)
      .closest('.form-group')
      .prop('disabled', disabled);
});
$('[name="personal_data[billType]"]').first().prop('checked', true);

$('[name="personal_data[emailRepeat]"]').each(function() {
    let $email = $('[name="personal_data[email]"]', this.form.elements);
    let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements);
    $($email).add($emailRepeat).on('input', function() {
        $emailRepeat.toggleValid($email.val() == $emailRepeat.val());
    }).trigger('change');
});

$('.application-form').on('submit-success', function(event, data) {
    $('.result').addClass('d-none');

    if (data.prize) {
        $('.result-success').removeClass('d-none');
        $('.result-prize-name').html(data.prize.name);
        $('.result-form-link').attr('href', data.formUrl);
    } else {
        $('.result-failed').removeClass('d-none');
    }
    setRegisteredData(data, '#modal-form-application');
    $('#modal-form-application')
      .on('hide.bs.modal', function() {
          $('#application')[0]?.scrollIntoView({ behavior: "smooth", block: 'center' });
          $('.result').addClass('d-none');
      })
      .modal('show');
});



// $('.game-run').on('click', function() {
//     // page swap
//     $('.page').addClass('d-none');
//     $('.page-result').removeClass('d-none');
//     $('.page-result').css('display', 'block');
//
//     // modal (uncomment if you need modal instead of page swap)
//     $('#modal-form-application').modal('show');
// });

$('.result-try-again').on('click', function() {
    // $('.result').removeClass('d-none');
    $('[name="personal_data[billType]"]').first().prop('checked', true);
});

$('[name="personal_data[noPesel]"]').on('change', function() {
    $('[name="personal_data[pesel]"]', this.form.elements).prop('disabled', $(this).prop('checked'));
    let fields = [
        '[name="personal_data[street]"]',
        '[name="personal_data[streetNr]"]',
        '[name="personal_data[flatNr]"]',
        '[name="personal_data[zipCode]"]',
        '[name="personal_data[city]"]',
        '[name="personal_data[birthDay]"]',
        '[name="personal_data[answer]"]',
    ];
    $(fields.join(', '), this.form.elements)
      .prop('required', !!$(this).prop('checked'))
      .closest('.form-group')
      .prop('disabled', !$(this).prop('checked'));
    $('[name="personal_data[flatNr]"]', this.form.elements)
      .prop('required', false);
    $('[name="personal_data[pesel]"]', this.form.elements)
      .prop('required', !$(this).prop('checked'));
}).trigger('change');

$('.winner-form').on('submit-success', function(event, data) {
    setRegisteredData(data, '#modal-form-winner');

    // page swap
    $('.page').addClass('d-none');
    $('.page-thank-you').removeClass('d-none');
    $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    $('#modal-form-winner').modal('show');
});

$('.contact-form').on('submit-success', function(event, data) {
    // setRegisteredData(data, 'section#contact');

    // page swap
    // $('.page').addClass('d-none');
    // $('.page-thank-you').removeClass('d-none');
    // $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    $('#modal-form-contact').modal('show');
});


$('#personal_data_billNr, #personal_data_billDate, #personal_data_billNip, #personal_data_billPos').each(function() {
    const $icon = $('<i class="fa-solid fa-circle-info"></i>')
    const $this = $(this);
    $icon.insertAfter($this);
    $icon.off('click').on('click', function() {
        $('#modal-paragon-content').attr('class', $this.attr('id'));
        $('#modal-paragon').modal('show');
    })
});
